import { EntityRoutes } from '../../model/route/entity.routes';
import { UserRoutes } from '../../model/route/user.routes';

export const USER_ROUTES = new UserRoutes('user', 'uuid');
export const CLIENT_ROUTES = new EntityRoutes('client', 'guid');
export const EMPLOYEE_ROUTES = new EntityRoutes('employee', 'uuid');
export const DOCUMENT_ROUTES = new EntityRoutes('document', 'guid');
export const SIGNER_ROUTES = new EntityRoutes('signer', 'uuid');
export const PASSPORT_ROUTES = new EntityRoutes('passport', 'uuid');
export const CERTIFICATE_ROUTES = new EntityRoutes('certificate', 'uuid');
export const ACCOUNT_ROUTES = {
  confirmUserSettings: '/account/confirm/settings'
}
