import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, FormControl, TextField } from '@mui/material';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import { QueryKey, useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { InputControlProps } from '../input.control.props';
import { useTranslation } from 'react-i18next';
import { useValidation } from '../../../../hooks/UseValidation';
import useDebounce from '../../../../hooks/UseDebounce';
import { AutocompleteResponse, SelectItem } from '../../../../model';
import { useErrorHandler } from '../../../../hooks/UseErrorHandler';
import { DataTypeQueryKey, EntityQueryKey } from '../../../../query/query.keys';
import { AutocompleteService } from '../../../../api/autocomplete.service';

interface IProps<T> extends InputControlProps<T> {
  entity: EntityQueryKey;
  options?: (queryKey: EntityQueryKey, term: string | (string | number)[], skip?: number) => Promise<AutocompleteResponse>;
  all?: boolean;
  auxQueryKey?: QueryKey;
}

interface IPropsSingle extends IProps<number | string | null> {
  multiple?: false;
  value: number | string | null;
}

interface IPropsMulti extends IProps<number[] | string[] | null> {
  multiple: true;
  value: number[] | string[];
}

export default function AutocompleteControl(props: PropsWithChildren<IPropsSingle | IPropsMulti>) {
  const { t } = useTranslation();
  const validate = useValidation(props.validators);
  const { validateOn } = useContext(ValidationContext);
  const errorHandler = useErrorHandler();

  const [inputValue, setInputValue] = useState('');
  const searchTerm = useDebounce(inputValue, 100);
  const [selected, setSelected] = useState<SelectItem | SelectItem[] | null>(props.multiple ? [] : null);
  const [errorText, setErrorText] = useState<string>();

  const optionsFn = props.options ?? AutocompleteService.fetchFn;

  const queryClient = useQueryClient();
  const { data: initialValue } = useQuery(
    [DataTypeQueryKey.Autocomplete, props.entity, props.multiple ? props.value : String(props.value ?? '')],
    () => (props.value ? optionsFn(props.entity, new Array<number | string>().concat(props.value)) : Promise.resolve(null)),
    {
      select: (data) => (data?.items && props.multiple ? data.items : data?.items?.[0] ?? null),
      onSuccess: (data) => {
        if (data === null || (Array.isArray(data) && data.length === 0)) {
          if (props.multiple) {
            props.onChange([]);
          }
          if (!props.multiple) {
            props.onChange(null);
          }
        }
      },
      onError: () => {
        setSelected(props.multiple ? [] : null);
      },
    },
  );
  useEffect(() => {
    setSelected(initialValue ?? (props.multiple ? [] : null));
    if (initialValue) {
      props.onChange(
        Array.isArray(initialValue) ? initialValue.map((nv) => nv.value) : (initialValue?.value as any),
        initialValue,
      );
    }
  }, [initialValue]);

  const fetchItems = ({ pageParam }: any) => {
    return optionsFn(props.entity, searchTerm, pageParam);
  };

  const {
    data: options,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery([props.entity, searchTerm.trim(), 'autocomplete', props.auxQueryKey], (params) => fetchItems(params), {
    getNextPageParam: (lastPage) => {
      if (props.all) return false;
      return lastPage.nextOffset;
    },
    select: (data) => {
      const items = data?.pages.flatMap((d) => d?.items ?? []) ?? [];
      return {
        pages: items,
        pageParams: [items.length],
      };
    },
    onError: (err) => {
      errorHandler(`Async select load failed`, err);
      setSelected(props.multiple ? [] : null);
    },
  });

  useEffect(() => {
    setErrorText(props.error ? t(props.error) : validate(props.value, props.tab ?? 'main'));
  }, [props.value, props.error, validateOn, props.validators]);

  return (
    <FormControl required={props.required} error={!!errorText} fullWidth size="small" margin="dense">
      <Autocomplete
        autoComplete
        value={selected}
        filterOptions={(x) => x}
        includeInputInList={!props.multiple}
        onChange={(event: React.SyntheticEvent, newValue: SelectItem | SelectItem[] | null) => {
          if (newValue != null && (!Array.isArray(newValue) || newValue.length)) {
            queryClient.setQueryData(
              [props.entity, Array.isArray(newValue) ? newValue.map((x) => x.value) : String(newValue.value)],
              { items: new Array<SelectItem>().concat(newValue), nextOffset: false }
            );
          }
          if (props.multiple && Array.isArray(newValue)) {
            props.onChange(newValue?.map((nv) => nv.value) as any, newValue);
            return;
          }
          if (!props.multiple && !Array.isArray(newValue)) {
            props.onChange(newValue?.value ?? null, newValue);
            return;
          }
          props.onChange(null);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        isOptionEqualToValue={(option, selectValue) => option.value === selectValue.value}
        getOptionLabel={(option) => option.text}
        options={options?.pages ?? []}
        loading={isFetching || isFetchingNextPage}
        multiple={props.multiple}
        disabled={props.disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={t(props.labelKey)}
            required={props.required}
            error={!!errorText}
            helperText={errorText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetching || isFetchingNextPage ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value}>
              {option.text}
            </li>
          );
        }}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;
            if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              if (hasNextPage && !isFetchingNextPage) {
                return fetchNextPage();
              }
            }
          },
        }}
      />
    </FormControl>
  );
}
