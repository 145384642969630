import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useValidation } from '../../../../hooks/UseValidation';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { InputControlProps } from '../input.control.props';
import { useTranslation } from 'react-i18next';

export interface IProps extends InputControlProps<string> {
    type?: string;
    multiline?: boolean;
    inputProps?: any;
    customComponentProps?: any,
    focused?: boolean;
    color?: any;
    autoComplete?: string;
    size?: 'medium';
}

export default function InputControl(props: IProps) {
    const { t } = useTranslation();
    const validate = useValidation(props.validators);
    const { validateOn } = useContext(ValidationContext);
    const [errorText, setErrorText] = useState<string>();

    useEffect(() => {
        setErrorText(props.error ? t(props.error) : validate(props.value, props.tab ?? 'main'));
    }, [props.value, props.error, validateOn, props.validators]);

    return (
      <FormControl fullWidth>
          <TextField
            size = {props.size ?? 'small'}
            name={props.name}
            margin={props.size ? 'normal' : 'dense'}
            required={props.required}
            fullWidth
            label={t(props.labelKey)}
            value={props.value ?? ''}
            type={props.type ?? 'text'}
            autoComplete={props.autoComplete}
            onChange={(event: ChangeEvent<HTMLInputElement>) => props.onChange(event.target.value)}
            error={!!errorText}
            helperText={errorText}
            multiline={props.multiline}
            disabled={props.disabled}
            InputLabelProps={{
                shrink: ['date', 'datetime', 'number', 'masked'].includes(props.type ?? '') || undefined,
            }}
            InputProps={props.inputProps}
            inputProps={props.customComponentProps}
            focused={props.focused}
            color={props.color}
          />
          {props.hintKey && <FormHelperText>{t(props.hintKey)}</FormHelperText>}
      </FormControl>
    );
}
