export enum EntityQueryKey {
  User = 'user',
  Client = 'client',
  Employee = 'employee',
  Document = 'document',
  Signer = 'signqueue',
  Region = 'dictionary/region',
  Passport = 'passport',
  Certificate = 'certificate',
}

export enum DataTypeQueryKey {
  Table = 'table',
  Autocomplete = 'autocomplete',
}
