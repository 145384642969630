import { useTranslation } from 'react-i18next';
import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { SelectItem, ClientBrief } from '../../../model';
import { CLIENT_ROUTES } from '../../route';
import { ClientService } from '../../../api/client/client.service';
import { useGroupActions, useRowActions } from '../../../components/common/data/DataTable/table.utils';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../../auth/auth.service';

export function useActions(): [TableRowAction<ClientBrief>[], TableGroupAction[], SelectItem[]] {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = AuthService.getUser();

  const rowActions = useRowActions<ClientBrief>({
    remove: {
      action: (row) => ClientService.remove(row.uuid),
      hide: (row) => !row.activeVersion,
    },
    toggleActive: {
      action: (row) => ClientService.toggle('isActive', row.uuid, !row.isActive),
      hide: (row) => !row.activeVersion,
      confirm: (row) => ({
        description: `client:confirm.toggleActive.${!row.isActive}`,
      }),
    },
    other: [
      {
        value: 'toggleVerified',
        text: (row) => t(`client:button.toggleVerified.${!row.isVerified}`),
        action: (row) => ClientService.toggle('isVerified', row.uuid, !row.isVerified),
        hide: (row) => !row.activeVersion || !row.inn,
        confirm: (row) => ({
          description: `client:confirm.toggleVerified.${!row.isVerified}`,
        }),
      },
      {
        value: 'edit',
        text: () => t('common:button.edit'),
        action: (row) => navigate(CLIENT_ROUTES.edit(row.uuid)),
        hide: (row) => !user?.isAdmin || !row.activeVersion,
        isNavigate: true,
      },
    ],
  });

  const groupActions = useGroupActions({
    remove: (selected) => ClientService.remove(selected as string[]),
    setActive: (selected) => ClientService.toggle('isActive', selected as string[], true),
    removeActive: (selected) => ClientService.toggle('isActive', selected as string[], false),
    other: [
      {
        value: 'setVerified',
        text: t(`client:button.toggleVerified.true`),
        action: (sm) => ClientService.toggle('isVerified', sm as string[], true),
        confirm: {
          description: t('client:confirm.toggleVerified.true'),
        },
      },
      {
        value: 'removeVerified',
        text: t(`client:button.toggleVerified.false`),
        action: (sm) => ClientService.toggle('isVerified', sm as string[], false),
        confirm: {
          description: t('client:confirm.toggleVerified.false'),
        },
      },
    ],
  });

  const toolbarActions = [
    {
      value: CLIENT_ROUTES.create(),
      text: t('common:button.add'),
    },
  ];

  return [rowActions, groupActions, toolbarActions];
}
