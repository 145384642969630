import { AtLeastOne } from '../../../../model/type';
import { PropsWithChildren } from 'react';

interface IProps<T extends Iterable<any>> {
  showType: AtLeastOne<T>;
  actualType: T;
}

export function ConditionalBlock<T extends Iterable<any>>({ showType, actualType, children }: PropsWithChildren<IProps<T>>) {
  return showType.includes(actualType) ? <>{children}</> : null;
}