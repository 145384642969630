import { useTranslation } from 'react-i18next';
import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { SelectItem, PassportBrief } from '../../../model';
import { PASSPORT_ROUTES } from '../../route';
import { useGroupActions, useRowActions } from '../../../components/common/data/DataTable/table.utils';
import { useNavigate } from 'react-router-dom';
import { PassportService } from '../../../api/passport/passport.service';

export function useActions(): [TableRowAction<PassportBrief>[], TableGroupAction[], SelectItem[]] {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const rowActions = useRowActions<PassportBrief>({
    remove: {
      action: (row) => PassportService.remove(row.uuid),
      hide: (row) => row.isDeleted,
    },
    toggleActive: {
      text: (row) => t(`passport:button.toggleActive.${!(row as any).isActive}`),
      action: (row) => PassportService.toggle('isActive', row.uuid, !row.isActive),
      hide: (row) => row.isDeleted,
      confirm: (row) => ({
        description: `passport:confirm.toggleActive.${!row.isActive}`,
      }),
    },
    other: [
      {
        value: 'edit',
        text: () => t('common:button.edit'),
        action: (row) => navigate(PASSPORT_ROUTES.edit(row.uuid)),
        hide: (row) => row.isVerified,
        isNavigate: true,
      },
    ],
  });

  const groupActions = useGroupActions({
    remove: (selected) => PassportService.remove(selected as string[]),
    removeActive: (selected) => PassportService.toggle('isActive', selected as string[], false),
  });

  const toolbarActions = [
    {
      value: PASSPORT_ROUTES.create(),
      text: t('common:button.add'),
    },
  ];

  return [rowActions, groupActions, toolbarActions];
}
