import { CertificateTypeEnum, LeftMenuItem } from '../../../../model';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SignOutRoute } from '../../../../page/signOut/routes';
import {
  CERTIFICATE_ROUTES,
  CLIENT_ROUTES,
  DOCUMENT_ROUTES,
  EMPLOYEE_ROUTES,
  PASSPORT_ROUTES,
  SIGNER_ROUTES,
  USER_ROUTES,
} from '../../../../page/route';

export const LEFT_MENU_ITEMS: LeftMenuItem[] = [
  {
    id: '',
    icon: solid('users'),
    textKey: 'todo...',
    path: '/',
  },
];

export const SECONDARY_MENU_ITEMS: LeftMenuItem[] = [
  {
    id: 'help',
    icon: solid('question'),
    textKey: 'secondary.help',
    path: '/help',
  },
  {
    id: 'support',
    icon: solid('life-ring'),
    textKey: 'secondary.support',
    path: '/help',
  },
  {
    id: 'logout',
    icon: solid('arrow-right-from-bracket'),
    textKey: 'secondary.logout',
    path: SignOutRoute.SIGN_OUT,
  },
];

export const ADMIN_MENU_ITEMS: LeftMenuItem[] = [
  {
    id: 'user-list',
    icon: solid('users'),
    textKey: 'leftMenu:main.users',
    path: USER_ROUTES.list(),
  },
  {
    id: 'client-list',
    icon: solid('id-card'),
    textKey: 'leftMenu:main.clients',
    path: CLIENT_ROUTES.list(),
  },
  {
    id: 'employee-list',
    icon: solid('id-badge'),
    textKey: 'leftMenu:main.employees',
    path: EMPLOYEE_ROUTES.list(),
  },
  {
    id: 'passport-list',
    icon: solid('passport'),
    textKey: 'leftMenu:main.passports',
    path: PASSPORT_ROUTES.list(),
  },
  {
    id: 'document-list',
    icon: solid('file-contract'),
    textKey: 'leftMenu:main.documents',
    path: DOCUMENT_ROUTES.list(),
  },
  {
    id: 'signer-list',
    icon: solid('file-signature'),
    textKey: 'leftMenu:main.signers',
    path: SIGNER_ROUTES.list(),
  },
  {
    id: 'certificate-list',
    icon: solid('certificate'),
    textKey: 'leftMenu:main.certificates.root',
    path: '/certificates',
    children: [
      {
        id: `certificate-${CertificateTypeEnum.INNER_OPENSSL}`,
        textKey: `leftMenu:main.certificates.${CertificateTypeEnum.INNER_OPENSSL}`,
        path: CERTIFICATE_ROUTES.list(`/${CertificateTypeEnum.INNER_OPENSSL}`),
      },
    ],
  },
];
