import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useErrorHandler } from '../../../hooks/UseErrorHandler';
import { EntityQueryKey } from '../../../query/query.keys';
import { ClientBrief, ListResponseDto, OrganizationTypeEnum } from '../../../model';
import { ErrorRoute } from '../../error/routes';
import { EditPage } from '../../../components/common/layout/EditPage';
import { EditClientValidator } from '../../../service/validator/client';
import { ClientEditDto } from '../../../model/dto/client';
import { ClientService } from '../../../api/client/client.service';
import { CLIENT_ROUTES } from '../../route';
import { useOptions } from '../../../hooks/UseOptions';
import RadioControl from '../../../components/common/ui/RadioControl';
import InputControl from '../../../components/common/ui/InputControl';
import AutocompleteControl from '../../../components/common/ui/AutocompleteControl';
import CheckboxControl from '../../../components/common/ui/CheckboxControl';
import { MaskedInput } from '../../../components/common/ui/MaskedInput';
import DateControl from '../../../components/common/ui/DateControl';
import { useTranslation } from 'react-i18next';
import { IsEmail, Length, NotEmpty, NumberStringValidator } from '../../../hooks/UseValidation/validators';
import { AxiosResponse } from 'axios';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { CheckCircleOutlined } from '@mui/icons-material';
import { resetQueryFn } from '../../../utils';
import { useDocumentTitle } from '../../../hooks/UseDocumentTitle';
import { PhoneControl } from '../../../components/common/ui/PhoneControl';
import { ConditionalBlock } from '../../../components/common';

export function ClientEdit() {
  const uuid = useParams<{ uuid: string }>().uuid;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  const [loading, setLoading] = useState(false);
  const types = useOptions(OrganizationTypeEnum, 'client:type.short.')
  const [apiErrors, setApiErrors] = useState<Record<string, string>>({});

  const { data: entity } = useQuery([EntityQueryKey.Client, uuid], () => ClientService.get<ClientBrief>(uuid ?? 'false', 'version'), {
    enabled: !!uuid,
    onSuccess: (data) => {
      if (data.isAdmin || !data.activeVersion) {
        navigate(ErrorRoute.ACCESS_DENIED, { replace: true });
      }
      setDto(new ClientEditDto(data));
    },
  });
  useDocumentTitle({ params: [entity?.shortName] });
  const [dto, setDto] = useState(new ClientEditDto(entity));
  const updateDto = (key: keyof ClientEditDto, val: any) => setDto((prev) => ({ ...prev, [key]: val }));
  const innMask = (dto: ClientEditDto) => dto.type === OrganizationTypeEnum.JURIDICAL ? '0000000000' : '000000000000';

  const { data: pClient } = useQuery([EntityQueryKey.Client, OrganizationTypeEnum.PHYSICAL, dto.user], () => ClientService.list<any>({
      filter: { type: OrganizationTypeEnum.PHYSICAL, user: dto.user, isActive: true }
    }), {
    enabled: !!dto.user,
    onSuccess: (data) => {
      if (data?.items?.length && dto.user && dto.type === OrganizationTypeEnum.INDIVIDUAL) {
        setDto((prev) => ({ ...prev, inn: data.items[0].inn }));
      }
    },
  });
  const { data: iClient } = useQuery([EntityQueryKey.Client, OrganizationTypeEnum.INDIVIDUAL, dto.user], () => ClientService.list<any>({
      filter: { type: OrganizationTypeEnum.INDIVIDUAL, user: dto.user, isActive: true }
    }), {
    enabled: !!dto.user,
    onSuccess: (data) => {
      if (data?.items?.length && dto.user && dto.type === OrganizationTypeEnum.PHYSICAL) {
        setDto((prev) => ({ ...prev, inn: data.items[0].inn }));
      }
    },
  });

  const { mutateAsync: toggleVerified } = useMutation<null | AxiosResponse<{ uuid: string }>>(() => {
      if (!uuid || !entity) return Promise.resolve(null);

      setLoading(true);
      return ClientService.toggle('isVerified', uuid, !entity.isVerified);
    },
    {
      onSuccess: async (data) => {
        if (entity && data != null) {
          await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, EntityQueryKey.Client) });
          navigate(CLIENT_ROUTES.edit(data.data.uuid), { replace: true })
        }
      },
      onError: (error) => {
        errorHandler('client verify toggle', error);
      },
      onSettled: () => {
        setLoading(false);
      },
    },
  );

  useEffect(() => {
    if (dto.user) {
      if (dto.type === OrganizationTypeEnum.PHYSICAL && !!iClient?.items?.length) {
        setDto((prev) => ({ ...prev, inn: iClient.items[0].inn }));
      }
      if (dto.type === OrganizationTypeEnum.INDIVIDUAL && !!pClient?.items?.length) {
        setDto((prev) => ({ ...prev, inn: pClient.items[0].inn }));
      }
    }
  }, [dto.user, dto.type, pClient?.items, iClient?.items])

  const disableActions = useMemo(() =>
    !!dto.user && !uuid && ((dto.type === OrganizationTypeEnum.PHYSICAL && !!pClient?.items?.length) ||(dto.type === OrganizationTypeEnum.INDIVIDUAL && !!iClient?.items?.length)),
    [uuid, dto.user, dto.type, pClient, iClient]);

  const clientError = useCallback((uuid: string|undefined, dto: ClientEditDto, pClient?: ListResponseDto<any>, iClient?: ListResponseDto<any>) => {
    if (!!uuid || !dto.user) {
      return null;
    }
    switch(dto.type) {
      case OrganizationTypeEnum.PHYSICAL:
        return !!pClient?.items?.length ? t('client:physicalExists') : null;
      case OrganizationTypeEnum.INDIVIDUAL:
        return !!iClient?.items?.length ? t('client:individualExists') : null
      default:
        return null;
    }
  }, []);

  return (
    <EditPage titleKey="client:edit.pageTitle" titleParams={[entity?.shortName]} validator={EditClientValidator} dto={dto} queryKey={EntityQueryKey.Client} routes={CLIENT_ROUTES} service={ClientService}
              disableActions={disableActions} apiErrors={setApiErrors}>
      <RadioControl row items={types} labelKey="client:field.type" value={dto.type} onChange={(val) => updateDto('type', val)} disabled={!!uuid}/>
      <AutocompleteControl required value={dto.user} entity={EntityQueryKey.User} labelKey="client:field.owner" onChange={(val) => updateDto('user', val)} validators={[NotEmpty]} disabled={!!uuid}
                           error={clientError(uuid, dto, pClient, iClient)}/>
      {dto.type !== OrganizationTypeEnum.PHYSICAL && dto.user && (<>
        <InputControl labelKey="client:field.physical" value={pClient?.items?.[0]?.shortName ?? t('client:physicalMissed')} disabled={true} onChange={() => null}
                      inputProps={{
                        endAdornment:
                          (pClient?.items?.[0]?.isVerified) ? <CheckCircleOutlined color="success" />
                            : null
                      }}/>
      </>)}
      <CheckboxControl labelKey="common:field.isActiveM" value={dto.isActive} onChange={(val) => updateDto('isActive', val)}/>
      <InputControl required={dto.type !== OrganizationTypeEnum.PHYSICAL} type="masked" labelKey="client:field.inn" value={dto.inn} onChange={(val) => updateDto('inn', val)}
                    disabled={entity?.isVerified || (dto.type === OrganizationTypeEnum.PHYSICAL && !!iClient?.items?.length) || (dto.type === OrganizationTypeEnum.INDIVIDUAL && !!pClient?.items?.length)}
                    customComponentProps={{ mask: innMask(dto) }} validators={dto.type === OrganizationTypeEnum.PHYSICAL ? [] : [NotEmpty]}
                    error={apiErrors['SGNX:VLDN:CLNT:INN_REQ_ISINN']}
                    inputProps={{
                      inputComponent: MaskedInput,
                      endAdornment:
                        (uuid && !!entity) ? <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => toggleVerified()}
                              edge="end"
                              disabled={!entity.inn || loading}
                            >
                              {loading
                                ? <CircularProgress size={20} sx={{ mr: '2px' }} />
                                : <CheckCircleOutlined color={entity.isVerified ? 'success' : 'disabled'} />
                              }
                            </IconButton>
                          </InputAdornment>
                          : null
                    }}/>
      <ConditionalBlock showType={[OrganizationTypeEnum.PHYSICAL]} actualType={dto.type}>
        <InputControl type="masked" labelKey="client:field.snils" value={dto.snils ?? ''} onChange={(val) => updateDto('snils', val)}
                      inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '000-000-000 00', unmask: false }} disabled={entity?.isVerified}
                      error={apiErrors['SGNX:VLDN:CLNT:SNILS_REQ_ISSNILS']}/>
      </ConditionalBlock>
      <ConditionalBlock showType={[OrganizationTypeEnum.INDIVIDUAL]} actualType={dto.type}>
        <InputControl required type="masked" labelKey="client:field.ogrnip" value={dto.ogrnip ?? ''} onChange={(val) => updateDto('ogrnip', val)}
                      inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '000000000000000' }} disabled={entity?.isVerified} validators={[NotEmpty]}
                      error={apiErrors['SGNX:VLDN:CLNT:OGRNIP_REQ_ISOGRN']}/>
      </ConditionalBlock>
      <ConditionalBlock showType={[OrganizationTypeEnum.JURIDICAL]} actualType={dto.type}>
        <InputControl required type="masked" labelKey="client:field.ogrn" value={dto.ogrn ?? ''} onChange={(val) => updateDto('ogrn', val)}
                      inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '0000000000000' }} disabled={entity?.isVerified} validators={[NotEmpty]}
                      error={apiErrors['SGNX:VLDN:CLNT:OGRN_REQ_ISOGRN']}/>
      </ConditionalBlock>
      <ConditionalBlock showType={[OrganizationTypeEnum.JURIDICAL]} actualType={dto.type}>
        <InputControl required type="masked" labelKey="client:field.kpp" value={dto.kpp ?? ''} onChange={(val) => updateDto('kpp', val)}
                      inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '000000000' }} disabled={entity?.isVerified} validators={[NotEmpty]}
                      error={apiErrors['SGNX:VLDN:CLNT:KPP_REQ_ISKPP']}/>
      </ConditionalBlock>
      <ConditionalBlock showType={[OrganizationTypeEnum.JURIDICAL]} actualType={dto.type}>
        <InputControl required labelKey="client:field.fullName" value={dto.fullName} onChange={(val) => updateDto('fullName', val)} validators={[NotEmpty, Length(511)]}/>
      </ConditionalBlock>
      <ConditionalBlock showType={[OrganizationTypeEnum.JURIDICAL]} actualType={dto.type}>
        <InputControl required={dto.type === OrganizationTypeEnum.JURIDICAL} labelKey="client:field.shortName" value={dto.shortName} onChange={(val) => updateDto('shortName', val)} validators={[NotEmpty, Length(255)]}/>
      </ConditionalBlock>
      <AutocompleteControl required value={dto.guidRegion} entity={EntityQueryKey.Region} labelKey="client:field.region" onChange={(val) => updateDto('guidRegion', val)} validators={[NotEmpty]}/>
      <InputControl required type="masked" labelKey="client:field.index" value={dto.index} onChange={(val) => updateDto('index', val)}
                    inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '000000' }} validators={[NotEmpty, NumberStringValidator, Length(6, 6)]} />
      <InputControl labelKey="client:field.district" value={dto.district} onChange={(val) => updateDto('district', val)} validators={[Length(255)]}/>
      <InputControl required labelKey="client:field.locality" value={dto.locality} onChange={(val) => updateDto('locality', val)} validators={[NotEmpty, Length(255)]}/>
      <InputControl labelKey="client:field.street" value={dto.street} onChange={(val) => updateDto('street', val)} validators={[Length(255)]}/>
      <InputControl labelKey="client:field.house" value={dto.house} onChange={(val) => updateDto('house', val)} validators={[Length(255)]}/>
      <InputControl labelKey="client:field.building" value={dto.building} onChange={(val) => updateDto('building', val)} validators={[Length(255)]}/>
      <InputControl labelKey={`client:field.room.${dto.type}`} value={dto.room} onChange={(val) => updateDto('room', val)} validators={[Length(255)]}/>
      <InputControl labelKey="client:field.additionalInfo" value={dto.additionalInfo} onChange={(val) => updateDto('additionalInfo', val)} validators={[Length(255)]}/>
      <ConditionalBlock showType={[OrganizationTypeEnum.INDIVIDUAL, OrganizationTypeEnum.JURIDICAL]} actualType={dto.type}>
        <InputControl required type="email" labelKey="common:field.email" value={dto.email} onChange={(val) => updateDto('email', val)} validators={[NotEmpty, IsEmail, Length(255)]}/>
      </ConditionalBlock>
      <ConditionalBlock showType={[OrganizationTypeEnum.INDIVIDUAL, OrganizationTypeEnum.JURIDICAL]} actualType={dto.type}>
        <PhoneControl value={dto.phone} onChange={(val) => updateDto('phone', val)} />
      </ConditionalBlock>
      <ConditionalBlock showType={[OrganizationTypeEnum.INDIVIDUAL, OrganizationTypeEnum.JURIDICAL]} actualType={dto.type}>
        <DateControl labelKey="client:field.liquidationDate" value={dto.liquidationDate} onChange={(val) => updateDto('liquidationDate', val)}/>
      </ConditionalBlock>
    </EditPage>
  );
}